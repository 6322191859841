
























import { Component, Prop, Vue } from 'vue-property-decorator'

import TeamMemberEntity from '@/modules/shared/entities/TeamMember'

@Component
export default class TeamMember extends Vue {
  @Prop({ required: true })
  private readonly member!: TeamMemberEntity;

  private get profileImage(): string {
    return this.member.image?.src ?? ''
  }

  private get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }
}
