













import { Component, Prop, Vue } from 'vue-property-decorator'

import TeamMember from '@/modules/shared/entities/TeamMember'

@Component
export default class TeamSection extends Vue {
  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: true })
  private readonly members!: TeamMember[];

  @Prop({ required: false })
  private readonly showborder!: boolean;
}
