import { render, staticRenderFns } from "./TeamMember.vue?vue&type=template&id=431e5626&scoped=true&"
import script from "./TeamMember.vue?vue&type=script&lang=ts&"
export * from "./TeamMember.vue?vue&type=script&lang=ts&"
import style0 from "./TeamMember.vue?vue&type=style&index=0&id=431e5626&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "431e5626",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
installComponents(component, {VAvatar})
