

































































import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TeamMemberEntity from '@/modules/shared/entities/TeamMember'
import { ViewDataScope } from '@/modules/shared/entities/ViewData'

import ViewDataMixin from '@/modules/shared/mixins/ViewDataMixin'

import Headline from '@/modules/shared/components/Headline.vue'
import TeamMember from '../components/TeamMember.vue'
import TeamSection from '../components/TeamSection.vue'

const TeamModule = namespace('team')

@Component({
  components: {
    Headline,
    TeamMember,
    TeamSection,
  },
})
export default class Team extends Mixins(ViewDataMixin) {
  @TeamModule.Action
  private readonly fetchTeam!: () => Promise<void>;

  @TeamModule.Getter
  private readonly instructor!: TeamMemberEntity[];

  @TeamModule.Getter
  private readonly postDocStudents!: TeamMemberEntity[];

  @TeamModule.Getter
  private readonly docStudents!: TeamMemberEntity[];

  @TeamModule.Getter
  private readonly mastering!: TeamMemberEntity[];

  @TeamModule.Getter
  private readonly scientificInitiation!: TeamMemberEntity[];

  @TeamModule.Getter
  private readonly alumni!: TeamMemberEntity[];

  viewDataScope = ViewDataScope.team

  mounted() {
    this.fetchTeam()
  }
}
